<template>
    <div>
        <ly-dialog v-model="dialogVisible" width="560px" title="审核" @closed="handleClose">
            <el-form :model="formData" :rules="rules" ref="rulesForm" label-position="right" label-width="100px">
                <el-form-item label="银行名称：" v-if="formData.type == 3">
                    {{ formData.userinfo.bank_name }}
                </el-form-item>
                <el-form-item label="银行卡号：" v-if="formData.type == 3">
                    {{ formData.userinfo.bank_no }}
                </el-form-item>
                <el-form-item label="提交金额：">
                    {{ formData.money }}
                </el-form-item>
                <el-form-item label="审核结果：" prop="action">
                    <el-radio-group v-model="formData.action" :disabled="!isaudit">
                        <el-radio :label="20">审核通过</el-radio>
                        <el-radio :label="30">审核未通过</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="未通过原因：" prop="audit_remarks">
                    <el-input type="textarea" v-model.trim="formData.audit_remarks" :disabled="!isaudit" maxlength="200"
                        show-word-limit clearable placeholder="请输入" style="width:100%"></el-input>
                </el-form-item>
            </el-form>
            <template v-slot:footer>
                <el-button type="primary" @click="submitData" :loading="loadingSave" v-if="isaudit">提交</el-button>
            </template>
        </ly-dialog>
    </div>
</template>

<script>
import LyDialog from "@/components/dialog/dialog";
import { fmallBlcRecordAudit } from "@/api/api";
import { deepClone } from "@/utils/util";
export default {
    name: "auditModuleCashout",
    components: { LyDialog },
    data() {
        return {
            dialogVisible: false,
            loadingSave: false,
            loadingTitle: '',
            tempdata: "",
            formData: {
                id: '',
                action: 20,
                audit_remarks: '',
            },
            rules: {
                // action: [
                //     {required: true, message: '请选择审核结果',trigger: 'blur'}
                // ]
            },
            statusList: [
                { id: 'pass', name: "审核通过" },
                { id: 'deny', name: "审核未通过" },
            ],
            isaudit: false
        }
    },
    methods: {
        handleClose() {
            this.$emit('closed')
        },
        handleOpen(item, flag, isaudit) {
            this.dialogVisible = true
            this.loadingTitle = flag
            this.isaudit = isaudit
            if (item) {
                this.formData = deepClone(item)
                if (this.formData.audit_status == 20 || this.formData.audit_status == 30) {
                    this.formData.action = this.formData.audit_status
                } else {
                    this.formData.action = 20
                }
            }
        },
        submitData() {
            this.$refs['rulesForm'].validate(obj => {
                if (obj) {
                    let netData = {
                        'action': this.formData.action,
                        'audit_remarks': this.formData.audit_remarks,
                        'id': this.formData.id
                    }
                    this.loadingSave = true
                    fmallBlcRecordAudit(netData).then(res => {
                        this.loadingSave = false
                        if (res.code == 2000) {
                            this.$message.success(res.msg)
                            this.handleClose()
                            this.$emit('refreshData')
                        } else {
                            this.$message.warning(res.msg)
                        }
                    })
                }
            })
        },

    }
}
</script>

