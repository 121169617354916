<template>
    <div>
        <ly-dialog v-model="dialogVisible" :title="loadingTitle" width="640px" top="10px" @closed="handleClose"
            :fullscreen="false">
            <el-form :inline="false" :model="formData" :rules="rules" ref="rulesForm" label-width="auto">
                <el-form-item label="店铺名称：" prop="shop_name">
                    {{ formData.shop_name }}
                </el-form-item>
                <el-form-item label="店铺分类：" prop="shop_type_name">
                    {{ formData.shop_type_name }}
                </el-form-item>
                <el-form-item label="联系人：" prop="contact_name">
                    {{ formData.contact_name }}
                </el-form-item>
                <el-form-item label="联系电话：" prop="contact_mobile">
                    {{ formData.contact_mobile }}
                </el-form-item>
                <el-form-item label="地址：" prop="areaname">
                    {{ formData.areaname }}
                </el-form-item>
                <el-form-item label="详细地址：" prop="place">
                    {{ formData.place }}
                </el-form-item>
                <el-form-item label="简介：" prop="desc">
                    <el-input type="textarea" :disabled="true" v-model.trim="formData.desc"
                        :autosize="{ minRows: 2, maxRows: 4 }"></el-input>
                </el-form-item>
                <el-form-item label="头像：" prop="avatar">
                    <el-image preview-teleported :src="formData.avatar" :preview-src-list="[formData.avatar]"
                        style="width: 100px;height: 100px"></el-image>
                </el-form-item>
                <el-form-item label="实力展示：" prop="imgs">
                    <el-image v-for="item in formData.imgs" preview-teleported :src="item" :preview-src-list="[item]"
                        v-bind:key="item.id" style="width: 100px;height: 100px"></el-image>
                </el-form-item>
                <el-form-item label="审核：" prop="action">
                    <el-radio-group v-model="formData.action" :disabled="!isaudit">
                        <el-radio :label="20">同意</el-radio>
                        <el-radio :label="30">拒绝</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="未通过原因：" prop="audit_remarks">
                    <el-input :disabled="!isaudit" type="textarea" v-model.trim="formData.audit_remarks" clearable
                        placeholder="请输入" style="width:100%"></el-input>
                </el-form-item>
            </el-form>
            <template #footer>
                <el-button @click="handleClose" :loading="loadingSave">取消</el-button>
                <el-button type="primary" @click="submitData" :loading="loadingSave" v-if="isaudit">确定</el-button>
            </template>
        </ly-dialog>
    </div>
</template>

<script>
import LyDialog from "@/components/dialog/dialog";
import { deepClone } from "@/utils/util"
import { fmallShopAudit } from "@/api/api"
export default {
    emits: ['refreshData', 'closed'],
    name: "addModuleShop",
    components: { LyDialog },
    data() {
        return {
            dialogVisible: false,
            loadingSave: false,
            loadingTitle: '',
            peopleList: [],
            formData: {
                action: 10,
                audit_remarks: "",
            },
            rules: {
            },
            isaudit: false
        }
    },
    methods: {
        handleClose() {
            this.$emit('closed')
        },
        handleOpen(item, flag, isaudit) {
            this.loadingTitle = flag
            this.dialogVisible = true
            this.isaudit = isaudit
            if (item) {
                this.formData = deepClone(item)
                if (this.formData.audit_status == 20 || this.formData.audit_status == 30) {
                    this.formData.action = this.formData.audit_status
                } else {
                    this.formData.action = 20
                }
            }
        },
        submitData() {
            this.$refs['rulesForm'].validate(obj => {
                if (obj) {
                    if (this.isaudit) {
                        this.loadingSave = true
                        let netData = {
                            'action': this.formData.action,
                            'audit_remarks': this.formData.audit_remarks,
                            'id': this.formData.id
                        }
                        fmallShopAudit(netData).then(res => {
                            this.loadingSave = false
                            if (res.code == 2000) {
                                this.$message.success(res.msg)
                                this.handleClose()
                                this.$emit('refreshData')
                            } else {
                                this.$message.warning(res.msg)
                            }
                        })
                    }

                }
            })
        }

    }
}
</script>

