<template>
    <div>
        <ly-dialog v-model="dialogVisible" :title="loadingTitle" width="60%" top="10px" @closed="handleClose"
            :fullscreen="false" :draggable="false">
            <el-form :inline="false" :model="formData" :rules="rules" ref="rulesForm" label-position="right"
                label-width="auto">
                <div class="ly-title">
                    商品信息
                </div>
                <el-table border :data="formData.goods_info">
                    <el-table-column width="80" prop="order_type_name" label="类型"></el-table-column>
                    <el-table-column min-width="130" prop="product_name" label="商品名称"
                        show-overflow-tooltip></el-table-column>
                    <el-table-column width="80" prop="goods_info.cover" label="图片">
                        <template #default="scope">
                            <el-image :src="scope.row.goods_info.cover" style="width: 30px;height: 30px"
                                :preview-src-list="[scope.row.goods_info.cover]" :preview-teleported="true"></el-image>
                        </template>
                    </el-table-column>
                    <el-table-column min-width="110" prop="breed_spec" label="商品规格"></el-table-column>
                    <el-table-column width="80" prop="goods_info.level_name" label="等级"></el-table-column>
                    <el-table-column width="100" prop="count" label="商品数量"></el-table-column>
                    <el-table-column width="100" prop="spec_weight" label="商品重量"></el-table-column>
                    <el-table-column min-width="110" prop="price" label="成本价">
                        <template #default="scope">
                            {{ (parseFloat(scope.row.price) - parseFloat(scope.row.j_price)).toFixed(2)
                            }}
                        </template>
                    </el-table-column>
                    <el-table-column min-width="110" prop="j_price" label="商品加价"></el-table-column>
                    <el-table-column min-width="110" prop="price" label="商品价格"></el-table-column>
                    <el-table-column min-width="110" prop="username" label="总重量">
                        <template #default="scope">
                            {{ ((parseFloat(scope.row.spec_weight)) * scope.row.count).toFixed(1)
                            }}
                        </template>
                    </el-table-column>
                    <el-table-column min-width="110" prop="total_amount" label="总价"></el-table-column>
                </el-table>
                <div class="ly-title" style="margin-top: 10px;">
                    售后信息
                </div>
                <el-row :gutter="20">
                    <el-col :span="6">
                        <el-form-item label="订单编号：">
                            {{ formData.order_id }}
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="售后编号：">
                            {{ formData.order_no }}
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="下单时间：">
                            {{ formData.create_datetime }}
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="描述：">
                            {{ formData.remark }}
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="照片：">
                            <el-image v-for="item in formData.imgs" preview-teleported :src="item"
                                :preview-src-list="[item]" v-bind:key="item.id"
                                style="width: 100px;height: 100px;margin-right: 10px;"></el-image>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="联系电话：">
                            {{ formData.contact }}
                        </el-form-item>
                    </el-col>
                </el-row>
                <div class="ly-title" style="margin-top: 10px;">
                    审核信息
                </div>
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-form-item label="审核：" v-if="formData.audit_status == 10 && is_audit">
                            <el-radio-group v-model="auditData.action">
                                <el-radio :label="1">通过</el-radio>
                                <el-radio :label="2">未通过</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="售后状态：" v-else>
                            {{ formData.audit_status_name }}
                        </el-form-item>
                    </el-col>
                    <el-col :span="24" v-if="formData.audit_status == 10 && is_audit && auditData.action == 2">
                        <el-form-item label="原因：" class="is-required">
                            <el-input v-model="formData.audit_remarks" style="width: 100%;"
                                :autosize="{ minRows: 2, maxRows: 4 }" type="textarea"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24" v-if="formData.audit_status == 20 || formData.audit_status == 30">
                        <el-form-item label="原因：">
                            <span>{{
                                formData.audit_remarks }}</span>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24" v-if="!(!is_audit && formData.audit_status == 10)">
                        <el-form-item label="商家退款：">
                            <el-input v-model="formData.shop_amount" style="width: 100%;" :disabled="true"
                                v-if="formData.audit_status == 10 && is_audit"></el-input>
                            <span v-else>{{ formData.shop_amount }}</span>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24" v-if="!(!is_audit && formData.audit_status == 10)">
                        <el-form-item label="平台退款：">
                            <el-input v-model="formData.platform_amount" style="width: 100%;" :disabled="true"
                                v-if="formData.audit_status == 10 && is_audit"></el-input>
                            <span v-else>{{ formData.platform_amount }}</span>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" v-if="formData.audit_status != 10">
                        <el-form-item label="退款总额：">
                            {{ formData.total_amount }}
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <template #footer>
                <el-button @click="handleClose" :loading="loadingSave">取消</el-button>
                <el-button type="primary" @click="submitData" :loading="loadingSave"
                    v-if="formData.audit_status == 10 && is_audit">提交审核</el-button>
            </template>
        </ly-dialog>
    </div>
</template>

<script>
import { fmallOrderaftersalesDetail, fmallOrderaftersalesAudit } from "@/api/api";
import LyDialog from "@/components/dialog/dialog";
import LyUploadAvatar from "@/components/upload/avatar";
import { deepClone } from "@/utils/util"
export default {
    name: "addModuleMallOrderServiceDetail",
    components: { LyDialog, LyUploadAvatar },
    emits: ['refreshData', 'closed'],
    data() {
        return {
            dialogVisible: false,
            loadingSave: false,
            loadingTitle: '',
            defaultImg: require('../../../assets/img/avatar.jpg'),
            id: "",
            is_audit: false,
            formData: {
                imgs: [],
                audit_status: 10,
                goods_info: [],
            },
            tableData: [],
            rules: {
                // username: [
                //     { required: true, message: '请输入用户名', trigger: 'blur' }
                // ],
            },
            auditData: {
                id: '',
                action: 1,
            }
        }
    },
    methods: {
        handleClose() {
            this.$emit('closed')
        },
        handleOpen(item, flag, is_audit) {
            this.loadingTitle = flag
            this.dialogVisible = true
            if (item) {
                this.id = item.id

                this.auditData.id = item.id
                this.getOderDetail(this.id)
            }
            this.is_audit = is_audit
        },
        getOderDetail(id) {
            fmallOrderaftersalesDetail({ id: id }).then(res => {
                if (res.code === 2000) {
                    this.formData = res.data
                }
            })
        },
        submitData() {
            this.$refs['rulesForm'].validate(obj => {
                if (obj) {
                    this.loadingSave = true
                    let param = {
                        ...this.auditData
                    }
                    param.audit_remarks = this.formData.audit_remarks
                    if (!param.audit_remarks && this.auditData.action == 2) {
                        this.$message.warning("请填写拒绝原因")
                        return
                    }
                    fmallOrderaftersalesAudit(param).then(res => {
                        this.loadingSave = false
                        if (res.code == 2000) {
                            this.$message.success(res.msg)
                            this.dialogVisible = false;
                            this.handleClose()
                            this.$emit('refreshData')
                        } else {
                            this.$message.warning(res.msg)
                        }
                    })
                }
            })
        },
    }
}
</script>
<style scoped>
.ly-title {
    border-bottom: 1px solid #eee;
    margin-bottom: 20px;
    font-size: 17px;
    padding-bottom: 15px;
    color: #3c4a54;
    font-weight: bold;
}
</style>

