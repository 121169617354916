<template>
    <div :class="{ 'ly-is-full': isFull }">
        <div class="tableSelect" ref="tableSelect">
            <el-form :inline="false" :model="formInline" label-width="auto">
                <div style="width: 100%;">
                    <el-row :gutter="20">
                        <el-col :span="6">
                            <el-form-item label="订单编号：">
                                <el-input style="width:100%" v-model.trim="formInline.order_id" maxlength="60" clearable
                                    placeholder="订单编号" @change="search"></el-input>
                            </el-form-item>
                        </el-col>

                        <el-col :span="6">
                            <el-form-item label="用户昵称：">
                                <el-input style="width:100%" v-model.trim="formInline.nickname" maxlength="60" clearable
                                    placeholder="用户昵称" @change="search"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="手机号：">
                                <el-input style="width:100%" v-model.trim="formInline.mobile" maxlength="60" clearable
                                    placeholder="手机号" @change="search"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="收货人：">
                                <el-input style="width:100%" v-model.trim="formInline.address_name" maxlength="60" clearable
                                    placeholder="收货人" @change="search"></el-input>
                            </el-form-item>
                        </el-col>
                        <!-- <el-col :span="6">
                            <el-form-item label="所属商家：">
                                <el-input style="width:100%" v-model.trim="formInline.shopname" maxlength="60" clearable
                                    placeholder="所属商家" @change="search"></el-input>
                            </el-form-item>
                        </el-col> -->
                        <el-col :span="6">
                            <el-form-item label="所属代理：">
                                <el-input style="width:100%" v-model.trim="formInline.agent_name" maxlength="60" clearable
                                    placeholder="所属代理" @change="search"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="订单状态：">
                                <el-select style="width:100%" v-model="formInline.status" placeholder="请选择" clearable
                                    @change="search">
                                    <el-option v-for="item in statusList" :key="item.id" :label="item.name"
                                        :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="类型：">
                                <el-select style="width:100%" v-model="formInline.order_type" placeholder="请选择" clearable
                                    @change="search">
                                    <el-option v-for="item in typeList" :key="item.id" :label="item.name" :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="创建时间：">
                                <el-date-picker style="width:100%" v-model="timers" type="datetimerange"
                                    @change="timeChange" range-separator="至" start-placeholder="开始时间"
                                    end-placeholder="结束时间">
                                </el-date-picker>
                            </el-form-item>
                        </el-col>
                        <el-col :span="18">
                            <el-form-item label="">
                                <el-button @click="search" type="primary" icon="Search"
                                    v-show="hasPermission(this.$route.name, 'Search')">查询</el-button>
                                <el-button @click="handleEdit('', 'reset')" icon="Refresh">重置</el-button>
                                <!-- <el-button @click="handleEdit('', 'reset')" type="warning" icon="MessageBox"
                                    :disabled="multiple">批量发货</el-button> -->
                                <el-button v-show="hasPermission(this.$route.name, 'PrintOrder')"
                                    @click="handleEdit('', 'mPrintOrder')" type="info" icon="Printer"
                                    :disabled="multiple">批量打印货单</el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </div>

            </el-form>
        </div>

        <div class="table">
            <el-table :height="tableHeight" border :data="tableData" ref="tableref" v-loading="loadingPage"
                @selection-change="handleSelectionChange">
                <el-table-column type="selection" width="55" align="center"></el-table-column>
                <el-table-column min-width="160" prop="order_id" label="订单编号"></el-table-column>
                <el-table-column width="100" prop="goods_no" label="货架号"></el-table-column>
                <el-table-column min-width="110" prop="userinfo.nickname" label="用户昵称"></el-table-column>
                <el-table-column min-width="110" prop="userinfo.mobile" label="手机号"></el-table-column>
                <el-table-column min-width="110" prop="address_name" label="收货人"></el-table-column>
                <el-table-column min-width="110" prop="total_amount" label="金额"></el-table-column>
                <el-table-column min-width="110" prop="status_name" label="订单状态"></el-table-column>
                <!-- <el-table-column min-width="110" prop="username" label="售后状态"></el-table-column> -->
                <el-table-column min-width="110" prop="ruwei_type_name" label="入位类型"></el-table-column>
                <!-- <el-table-column min-width="110" prop="username" label="所属商家"></el-table-column> -->
                <el-table-column width="120" prop="agent_name" label="所属代理"></el-table-column>
                <el-table-column min-width="170" prop="create_datetime" label="创建时间"></el-table-column>
                <el-table-column label="操作" fixed="right" width="280">
                    <template #header>
                        <div style="display: flex;justify-content: space-between;align-items: center;">
                            <div>操作</div>
                            <div @click="setFull">
                                <el-tooltip content="全屏" placement="bottom">
                                    <el-icon><full-screen /></el-icon>
                                </el-tooltip>
                            </div>
                        </div>
                    </template>
                    <template #default="scope">
                        <span class="table-operate-btn" @click="handleEdit(scope.row, 'detail')"
                            v-show="hasPermission(this.$route.name, 'Retrieve')">详情</span>
                        <span class="table-operate-btn" @click="handleEdit(scope.row, 'ruwei')"
                            v-show="hasPermission(this.$route.name, 'Ruwei') && scope.row.status == 3">入位</span>
                        <span class="table-operate-btn" @click="handleEdit(scope.row, 'sendGoodsManual')"
                            v-show="hasPermission(this.$route.name, 'Deliver') && scope.row.status == 4">发货</span>
                        <span class="table-operate-btn" @click="handleEdit(scope.row, 'record')"
                            v-show="hasPermission(this.$route.name, 'OperateLog')">操作记录</span>
                        <span class="table-operate-btn" @click="handleEdit(scope.row, 'yunfei')"
                            v-show="hasPermission(this.$route.name, 'Yunfei') && scope.row.freight_type == 0 && scope.row.status != 8">运费</span>
                        <span class="table-operate-btn" v-show="hasPermission(this.$route.name, 'PrintOrder')"
                            @click="handleEdit(scope.row, 'printOrder')">打印货单</span>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <Pagination v-bind:child-msg="pageparm" @callFather="callFather"></Pagination>
        <addModule ref="addModuleFlag" @refreshData="getData" v-if="isDialogVisible" @closed="isDialogVisible = false">
        </addModule>
        <addModuleYunFei ref="addModuleYunfeiFlag" @refreshData="getData" v-if="isYunfeiDialogVisible"
            @closed="isYunfeiDialogVisible = false">
        </addModuleYunFei>
        <addModuleMallOrderDetail ref="addModuleDetailFlag" v-if="isDetailDialogVisible"
            @closed="isDetailDialogVisible = false">
        </addModuleMallOrderDetail>
        <addModuleMallOrderOPRecord ref="addModuleRecordFlag" v-if="isRecordlDialogVisible"
            @closed="isRecordlDialogVisible = false">
        </addModuleMallOrderOPRecord>
        <modulePrintOrderMultiple ref="moduleMPrintOrderFlog"></modulePrintOrderMultiple>
        <moduleDeliverGoodsManual @refreshData="getData" ref="moduleDeliverGoodsManualFlag"
            v-if="isDeliverGoodsManualVisible" @closed="isDeliverGoodsManualVisible = false">
        </moduleDeliverGoodsManual>
        <modulePrintOrder ref="modulePrintOrderFlog"></modulePrintOrder>
    </div>
</template>
<script>
import addModule from "./components/addModule";
import addModuleYunFei from "./components/addModuleYunFei";
import addModuleMallOrderDetail from "./components/addModuleMallOrderDetail";
import addModuleMallOrderOPRecord from "./components/addModuleMallOrderOPRecord";
import moduleDeliverGoodsManual from "./components/moduleDeliverGoodsManual";
import modulePrintOrderMultiple from "./components/modulePrintOrderMultiple";
import modulePrintOrder from "./components/modulePrintOrder";
import Pagination from "@/components/Pagination";
import { dateFormats, getTableHeight } from "@/utils/util";
import { fmallOrder, fmallOrderDelete } from '@/api/api'
export default {
    components: {
        Pagination,
        addModule,
        addModuleYunFei,
        addModuleMallOrderDetail,
        addModuleMallOrderOPRecord,
        moduleDeliverGoodsManual,
        modulePrintOrderMultiple,
        modulePrintOrder
    },
    name: 'mallOrderListManage',
    data() {
        return {
            isFull: false,
            tableHeight: 500,
            loadingPage: false,
            formInline: {
                page: 1,
                limit: 10,
            },
            defaultImg: require('../../assets/img/avatar.jpg'),
            pageparm: {
                page: 1,
                limit: 10,
                total: 0
            },
            statusList: [
                { id: 1, name: '待支付' },
                { id: 2, name: '拼团中' },
                { id: 3, name: '待入位' },
                { id: 4, name: '待发货' },
                { id: 5, name: '待收货' },
                { id: 6, name: '待评价' },
                { id: 7, name: '已完成' },
                { id: 8, name: '已取消' },
            ],
            typeList: [
                { id: 1, name: '零售' },
                { id: 2, name: '秒杀' },
                { id: 3, name: '团购' },
            ],
            timers: [],
            tableData: [],
            isDialogVisible: false,
            isYunfeiDialogVisible: false,
            isDetailDialogVisible: false,
            isRecordlDialogVisible: false,
            isDeliverGoodsManualVisible: false,
            // 选项框选中数组
            ids: [],
            // 选项框非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
        }
    },

    methods: {
        // 表格序列号
        getIndex($index) {
            // (当前页 - 1) * 当前显示数据条数 + 当前行数据的索引 + 1
            return (this.pageparm.page - 1) * this.pageparm.limit + $index + 1
        },
        setFull() {
            this.isFull = !this.isFull
            window.dispatchEvent(new Event('resize'))
        },
        //多选项框被选中数据
        handleSelectionChange(selection) {
            this.ids = selection.map(item => item.id);
            this.single = selection.length !== 1;
            this.multiple = !selection.length;
        },
        handelAddClick() {
            this.isDialogVisible = true
            this.$nextTick(() => {
                this.$refs.addModuleFlag.handleOpen(null, "新增")
            })
        },
        handleEdit(row, flag) {
            if (flag == 'detail') {
                this.isDetailDialogVisible = true
                this.$nextTick(() => {
                    this.$refs.addModuleDetailFlag.handleOpen(row, "订单详情")
                })

            } else if (flag == "sendGoodsManual") {
                this.isDeliverGoodsManualVisible = true
                this.$nextTick(() => {
                    this.$refs.moduleDeliverGoodsManualFlag.handleOpen(row, "发货")
                })
            }
            else if (flag == 'ruwei') {
                this.$router.push({ name: 'InsertionManage', query: { order_id: row.order_id } });
            }
            else if (flag == 'record') {
                this.isRecordlDialogVisible = true
                this.$nextTick(() => {
                    this.$refs.addModuleRecordFlag.handleOpen(row, "操作记录")
                })
            }
            else if (flag == 'yunfei') {
                this.isYunfeiDialogVisible = true
                this.$nextTick(() => {
                    this.$refs.addModuleYunfeiFlag.handleOpen(row, "运费")
                })
            } else if (flag == 'printOrder') {
                this.$refs.modulePrintOrderFlog.handleOpen(row)
            } else if (flag == 'mPrintOrder') {
                this.$refs.moduleMPrintOrderFlog.handleOpen(this.ids)
            }
            else if (flag == 'delete') {
                let vm = this
                vm.$confirm('您确定要删除选中的数据吗？', {
                    closeOnClickModal: false
                }).then(res => {
                    fmallOrderDelete({ id: row.id }).then(res => {
                        if (res.code == 2000) {
                            vm.$message.success(res.msg)
                            vm.search()
                        } else {
                            vm.$message.warning(res.msg)
                        }
                    })
                }).catch(() => {

                })
            }
            else if (flag == "reset") {
                this.formInline = {
                    page: 1,
                    limit: 10
                }
                this.pageparm = {
                    page: 1,
                    limit: 10,
                    total: 0
                }
                this.getData()
            }
        },
        /**
         * 从URL里下载文件
        */
        // 下载文件
        downloadFileURL(url) {
            var iframe = document.createElement("iframe")
            iframe.style.display = "none";
            iframe.src = url;
            document.body.appendChild(iframe);
        },
        exportDataBackend() {
            var params = {
                page: 1,
                limit: 9999,
            }
            // UsersUsersExportexecl(params).then(res => {
            //     if (res.code == 2000) {
            //         this.downloadFileURL(res.data.data)
            //         //this.$message.warning(res.data.data)
            //     }
            // })
        },
        callFather(parm) {
            this.formInline.page = parm.page
            this.formInline.limit = parm.limit
            this.getData()
        },
        search() {
            this.formInline.page = 1
            this.formInline.limit = 10
            this.getData()
        },
        //获取列表
        async getData() {
            this.loadingPage = true
            if (!!this.$route.query.creator_id) {
                this.formInline.creator_id = this.$route.query.creator_id
            }
            if (!!this.$route.query.order_id) {
                this.formInline.order_id = this.$route.query.order_id
            }
            fmallOrder(this.formInline).then(res => {
                this.loadingPage = false
                if (res.code == 2000) {
                    this.tableData = res.data.data
                    this.pageparm.page = res.data.page;
                    this.pageparm.limit = res.data.limit;
                    this.pageparm.total = res.data.total;
                }
            })
        },

        timeChange(val) {
            if (val) {
                this.formInline.beginAt = dateFormats(val[0], 'yyyy-MM-dd hh:mm:ss');
                this.formInline.endAt = dateFormats(val[1], 'yyyy-MM-dd hh:mm:ss');
            } else {
                this.formInline.beginAt = null
                this.formInline.endAt = null
            }
            this.search()
        },
        // 计算搜索栏的高度
        listenResize() {
            this.$nextTick(() => {
                this.getTheTableHeight()
            })
        },
        getTheTableHeight() {
            let tabSelectHeight = this.$refs.tableSelect ? this.$refs.tableSelect.offsetHeight : 0
            tabSelectHeight = this.isFull ? tabSelectHeight - 110 : tabSelectHeight
            this.tableHeight = getTableHeight(tabSelectHeight)
        }

    },
    created() {
        this.getData()
    },
    mounted() {
        // 监听页面宽度变化搜索框的高度
        window.addEventListener('resize', this.listenResize);
        this.$nextTick(() => {
            this.getTheTableHeight()
        })
    },

    unmounted() {
        // 页面销毁，去掉监听事件
        window.removeEventListener("resize", this.listenResize);
    },
}
</script>
