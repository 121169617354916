<template>
  <el-tabs v-model="activeName" @tab-click="handleClick" tab-position="left">
    <el-tab-pane label="密码设置" name="passwrod">
      <el-form ref="userPasswordForm" :model="userPasswordInfo" required-asterisk :label-position="position"
        :rules="passwordRules" center label-width="120px" style="margin: 50px auto">
        <el-form-item label="原密码：" required prop="oldPassword">
          <el-input size="large" v-model="userPasswordInfo.oldPassword" clearable style="width: 360px"></el-input>
        </el-form-item>
        <el-form-item required prop="newPassword" label="新密码：">
          <el-input size="large" type="password" v-model="userPasswordInfo.newPassword" clearable
            style="width: 360px"></el-input>
        </el-form-item>
        <el-form-item required prop="newPassword2" label="确认密码：">
          <el-input size="large" type="password" v-model="userPasswordInfo.newPassword2" clearable
            style="width: 360px"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="settingPassword" v-show="hasPermission(this.$route.name, 'Changepassword')">
            <i class="fa fa-check"></i>提交
          </el-button>
          <el-button @click="resetForm('passwordForm')" type="info"
            v-show="hasPermission(this.$route.name, 'Changepassword')">
            <i class="fa fa-reply-all"></i>重置
          </el-button>
        </el-form-item>
      </el-form>
    </el-tab-pane>
    <el-tab-pane label="账户管理" name="userInfo" v-if="userInfo.identity == 4">
      <el-form ref="userInfoForm" :model="userInfo" :disabled="!hasPermission(this.$route.name, 'Update')"
        required-asterisk :rules="userInforules" :label-position="position" center label-width="120px"
        style="margin: 50px auto">
        <div class="ly-title">
          基础信息
        </div>
        <el-form-item prop="avatar" label="头像:">
          <LyUploadAvatar v-model="userInfo.avatar" width="100px" height="100px"></LyUploadAvatar>
        </el-form-item>
        <el-form-item prop="name" label="名称:">
          <el-input size="large" v-model="userInfo.name" clearable style="width: 360px"></el-input>
        </el-form-item>
        <el-form-item label="手机号:" prop="mobile">
          <el-input size="large" v-model="userInfo.mobile" clearable style="width: 360px"></el-input>
        </el-form-item>
        <!-- <el-form-item label="邮箱:" prop="email">
              <el-input size="large" v-model="userInfo.email" clearable style="width: 360px" ></el-input>
            </el-form-item> -->
        <!-- <el-form-item label="性別:" prop="gender">
              <el-radio-group v-model="userInfo.gender">
                <el-radio :label="1">男</el-radio>
                <el-radio :label="0">女</el-radio>
                <el-radio :label="-1">未知</el-radio>
              </el-radio-group>
            </el-form-item> -->
        <div class="ly-title">
          银行信息
        </div>
        <el-form-item label="银行名称:" prop="bank_name">
          <el-input size="large" v-model="userInfo.bank_name" clearable style="width: 360px"></el-input>
        </el-form-item>
        <el-form-item label="银行卡号:" prop="bank_no">
          <el-input size="large" v-model="userInfo.bank_no" clearable style="width: 360px"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="updateInfo" type="primary" v-show="hasPermission(this.$route.name, 'Update')">
            <i class="fa fa-check"></i>
            更新
          </el-button>
          <!-- <el-button @click="resetForm('info')" type="info" v-show="hasPermission(this.$route.name,'Update')">
                <i class="fa fa-reply-all"></i>
                重置
              </el-button> -->
        </el-form-item>
      </el-form>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import { systemUserUserInfoEdit, systemUserUserInfo, systemUserChangePassword } from '@/api/api'
import { useMutitabsStore } from "@/store/mutitabs";
import LyUploadAvatar from "@/components/upload/avatar";
export default {
  name: "personalCenter",
  components: {
    LyUploadAvatar,
  },
  setup() {
    const mutitabsstore = useMutitabsStore()
    return { mutitabsstore }
  },
  data() {
    var validatePass = (rule, value, callback) => {
      const pwdRegex = new RegExp('(?=.*[0-9])(?=.*[a-zA-Z]).{8,30}')
      if (value === '') {
        callback(new Error('请输入密码'))
      } else if (value === this.userPasswordInfo.oldPassword) {
        callback(new Error('原密码与新密码一致'))
      } else if (!pwdRegex.test(value)) {
        callback(new Error('您的密码复杂度太低(密码中必须包含字母、数字)'))
      } else {
        if (this.userPasswordInfo.newPassword2 !== '') {
          this.$refs.userPasswordForm.validateField('newPassword2')
        }
        callback()
      }
    }
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.userPasswordInfo.newPassword) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      position: 'right',
      activeName: 'passwrod',
      userInfo: {
        name: '',
        gender: 1,
        mobile: '',
        avatar: '',
        email: '',
        identity: 1,
      },
      userInforules: {
        // avatar: [{ required: true, message: '请上传头像', trigger: 'blur' }],
        name: [{ required: true, message: '请输入昵称', trigger: 'blur' }],
        mobile: [
          { required: true, pattern: /^1[3|4|5|7|8|6]\d{9}$/, message: '请输入正确手机号', trigger: 'blur' }
        ],
        bank_name: [{ required: true, message: '请输入银行名称', trigger: 'blur' }],
        bank_no: [{ required: true, message: '请输入银行卡号', trigger: 'blur' }],
      },
      userPasswordInfo: {
        oldPassword: '',
        newPassword: '',
        newPassword2: ''
      },
      passwordRules: {
        oldPassword: [
          {
            required: true,
            message: '请输入原密码',
            trigger: 'blur'
          }
        ],
        newPassword: [{ validator: validatePass, trigger: 'blur' }],
        newPassword2: [{ validator: validatePass2, trigger: 'blur' }]
      }
    }
  },
  mounted() {
    this.getCurrentUserInfo()
  },
  methods: {
    /**
     * 获取当前用户信息
     */
    getCurrentUserInfo() {
      systemUserUserInfo().then(res => {
        if (res.code == 2000) {
          this.userInfo = res.data.data
        }

      })
    },
    /**
     * 更新用户信息
     */
    updateInfo() {
      const _self = this

      _self.$refs.userInfoForm.validate((valid) => {
        if (valid) {
          //console.log(_self.userInfo)
          systemUserUserInfoEdit(_self.userInfo).then(res => {
            if (res.code == 2000) {
              this.$message.success(res.msg)
              _self.getCurrentUserInfo()
            } else {
              this.$message.warning(res.msg)
            }
          })
        } else {
          // 校验失败
          // 登录表单校验失败
          this.$message.error('表单校验失败，请检查')
        }
      })
    },
    // 重置
    resetForm(name) {
      const _self = this
      if (name === 'info') {
        _self.getCurrentUserInfo()
      } else {
        _self.userPasswordInfo = {
          oldPassword: '',
          newPassword: '',
          newPassword2: ''
        }
      }
    },
    // tab切换,默认切换清除原字符
    handleClick(tab, event) {
      const _self = this
      // if (tab.paneName === 'userInfo') {
      //   _self.$refs.userPasswordForm.resetFields()
      // } else {
      //   _self.$refs.userInfoForm.resetFields()
      // }
    },
    /**
     * 重新设置密码
     */
    settingPassword() {
      const _self = this

      _self.$refs.userPasswordForm.validate((valid) => {
        if (valid) {
          const userId = this.mutitabsstore.getUserId
          if (userId) {
            const params = JSON.parse(JSON.stringify(_self.userPasswordInfo))
            params.id = userId
            systemUserChangePassword(params).then(res => {
              if (res.code == 2000) {
                // _self.activeName = 'userInfo'
                this.$message.success(res.msg)
              } else {
                this.$message.warning(res.msg)
              }
            })
          }
        } else {
          // 校验失败
          // 登录表单校验失败
          this.$message.error('表单校验失败，请检查')
        }
      })
    }

  }
}
</script>

<style scoped>
.ly-title {
  border-bottom: 1px solid #eee;
  margin-bottom: 20px;
  font-size: 17px;
  padding-bottom: 15px;
  color: #3c4a54;
  font-weight: bold;
}

.el-tabs .el-tabs__content {
  background-color: var(--el-bg-color) !important;
}

.el-tabs {
  background-color: var(--el-bg-color) !important;
}

::v-deep(.el-tabs__header) {
  margin-top: 20px !important;
}
</style>
