<template>
    <div>
        <ly-dialog v-model="dialogVisible" :title="loadingTitle" width="680px" :before-close="handleClose">
            <el-form :inline="false" :model="formData" :rules="rules" ref="rulesForm" label-position="right"
                label-width="auto">
                <el-form-item label="优惠券名称：" prop="name">
                    <el-input v-model.trim="formData.name" placeholder="请输入"></el-input>
                </el-form-item>
                <el-form-item label="优惠金额：" prop="price">
                    <el-input-number v-model="formData.price" style="width: 100%;" :controls="false"
                        placeholder="优惠金额"></el-input-number>
                </el-form-item>
                <el-form-item label="发放数量：" prop="total_num">
                    <el-input-number v-model="formData.total_num" :min="0" style="width: 100%;" :controls="false"
                        placeholder="发放数量"></el-input-number>
                </el-form-item>
                <el-form-item label="使用时间：" class="is-required">
                    <div style="display: flex;">
                        <el-date-picker v-model="formData.valid_begin_date" type="date" placeholder="开始日期"
                            format="YYYY-MM-DD" value-format="YYYY-MM-DD" style="width: 100%;" />
                        <div style="width: 100px;text-align: center;">至</div>
                        <el-date-picker v-model="formData.valid_end_date" type="date" placeholder="结束日期" format="YYYY-MM-DD"
                            value-format="YYYY-MM-DD" style="width: 100%;" />
                        <div style="width: 100px;text-align: right;">可用</div>
                    </div>
                </el-form-item>
                <el-form-item label="使用说明：" prop="desc">
                    <el-input v-model="formData.desc" :autosize="{ minRows: 2, maxRows: 4 }" type="textarea"
                        placeholder="请输入"></el-input>
                </el-form-item>
                <!-- <el-form-item label="排序：" prop="sort">
                    <el-input-number v-model="formData.sort" style="width: 100%;"></el-input-number>
                </el-form-item> -->
            </el-form>
            <template #footer>
                <el-button @click="handleClose" :loading="loadingSave">取消</el-button>
                <el-button type="primary" @click="submitData" :loading="loadingSave">确定</el-button>
            </template>
        </ly-dialog>
    </div>
</template>

<script>
import { fmallGoodsCouponAdd, fmallGoodsCouponEdit } from "@/api/api";
import LyDialog from "@/components/dialog/dialog";
import { deepClone } from "@/utils/util"
export default {
    components: { LyDialog },
    emits: ['refreshData'],
    name: "addModuleGoodsCoupon",
    data() {
        return {
            dialogVisible: false,
            loadingSave: false,
            loadingTitle: '',
            formData: {
                name: '',
            },
            rules: {
                name: [
                    { required: true, message: '请填写优惠券名称', trigger: 'blur' }
                ],
                price: [
                    { required: true, message: '请填写优惠金额', trigger: 'blur' }
                ],
                total_num: [
                    { required: true, message: '请填写发放数量', trigger: 'blur' }
                ],
            }
        }
    },
    mounted() {
        window.addEventListener("focusin", this.onFocusIn, true);
    },
    unmounted() {
        window.removeEventListener("focusin", this.onFocusIn);
    },
    methods: {
        onFocusIn(e) {
            e.stopImmediatePropagation()//阻止当前和后面的一系列事件
        },
        handleClose() {
            this.dialogVisible = false
            this.loadingSave = false
            this.formData = {
                name: '',
            }
        },
        addModuleFn(item, flag) {
            this.loadingTitle = flag
            this.dialogVisible = true
            if (item) {
                this.formData = deepClone(item)
            }
        },
        submitData() {
            this.$refs['rulesForm'].validate(obj => {
                if (obj) {
                    this.loadingSave = true
                    let param = {
                        ...this.formData
                    }
                    if (this.formData.id) {
                        fmallGoodsCouponEdit(param).then(res => {
                            this.loadingSave = false
                            if (res.code == 2000) {
                                this.$message.success(res.msg)
                                this.handleClose()
                                this.$emit('refreshData')
                            } else {
                                this.$message.warning(res.msg)
                            }
                        })
                    } else {
                        fmallGoodsCouponAdd(param).then(res => {
                            this.loadingSave = false
                            if (res.code == 2000) {
                                this.$message.success(res.msg)
                                this.handleClose()
                                this.$emit('refreshData')
                            } else {
                                this.$message.warning(res.msg)
                            }
                        })
                    }

                }
            })
        },

    }
}
</script>

<style>
.set-specs .el-form-item__content {
    background: #e6e6e6 !important;
}
</style>

