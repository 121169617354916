<template>
    <div>
        <ly-dialog v-model="dialogVisible" :title="loadingTitle" width="568px" @closed="handleClose" :fullscreen="true"
            :draggable="false">
            <el-form :inline="false" :model="formData" :rules="rules" ref="rulesForm" label-position="right"
                label-width="auto">
                <el-form-item label="选择分类：" prop="type">
                    <el-select v-model="formData.type" placeholder="请选择" style="width: 100%;" filterable clearable>
                        <el-option v-for=" item  in  typeList " :key="item.id" :label="item.name" :value="item.id" />
                    </el-select>
                </el-form-item>
                <el-form-item label="选择颜色：" prop="color">
                    <el-select v-model="formData.color" placeholder="请选择" style="width: 100%;" filterable clearable>
                        <el-option v-for=" item  in  colorList " :key="item.id" :label="item.name" :value="item.id" />
                    </el-select>
                </el-form-item>
                <el-form-item label="选择标签：" prop="label">
                    <el-select v-model="formData.label" placeholder="请选择" style="width: 100%;" filterable clearable>
                        <el-option v-for=" item  in  labelList " :key="item.id" :label="item.name" :value="item.id" />
                    </el-select>
                </el-form-item>
                <el-form-item label="品种名称：" prop="name">
                    <el-input v-model.trim="formData.name" placeholder="请输入"></el-input>
                </el-form-item>
                <el-form-item label="商品名称：" prop="goods_name">
                    <el-input v-model.trim="formData.goods_name" placeholder="请输入"></el-input>
                </el-form-item>
                <el-form-item label="鲜花类型：" prop="flower_type">
                    <el-radio-group v-model="formData.flower_type">
                        <el-radio :label="1">主花</el-radio>
                        <el-radio :label="2">配花</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="封面图：" prop="cover">
                    <LyUploadAvatar v-model="formData.cover" width="100px" height="100px"></LyUploadAvatar>
                </el-form-item>
                <el-form-item label="品种规格：" prop="breed_spec_type">
                    <el-radio-group v-model="formData.breed_spec_type">
                        <el-radio :label="1">通用配置</el-radio>
                        <el-radio :label="2">商家配置</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="品种规格：" prop="breed_spec" v-if="formData.breed_spec_type == 1" class="is-required">
                    <el-input v-model.trim="formData.breed_spec" placeholder="请输入（单位：/扎）"></el-input>
                </el-form-item>
                <el-form-item label="规格备注：" prop="spec_desc" v-if="formData.breed_spec_type == 1" class="is-required">
                    <el-input v-model.trim="formData.spec_desc" placeholder="请输入（5字内）" :maxlength="5"></el-input>
                </el-form-item>
                <el-form-item label="规格估重：" prop="spec_weight" v-if="formData.breed_spec_type == 1" class="is-required">
                    <el-input-number v-model.trim="formData.spec_weight" placeholder="请输入（单位：kg/扎）" style="width: 100%;"
                        :controls="false"></el-input-number>
                </el-form-item>
                <el-form-item label="排序：" prop="sort">
                    <el-input-number v-model="formData.sort" style="width: 100%;" :controls="false"
                        placeholder="数字越大越靠前"></el-input-number>
                </el-form-item>
            </el-form>
            <template #footer>
                <el-button @click="handleClose" :loading="loadingSave">取消</el-button>
                <el-button type="primary" @click="submitData" :loading="loadingSave">确定</el-button>
            </template>
        </ly-dialog>
    </div>
</template>

<script>
import { fmallGoodsBreedAdd, fmallGoodsBreedEdit, fmallGoodslabel, fmallGoodscolor, fmallGoodstype } from "@/api/api";
import LyDialog from "@/components/dialog/dialog";
import { deepClone } from "@/utils/util"
import LyUploadAvatar from "@/components/upload/avatar";
export default {
    components: { LyDialog, LyUploadAvatar },
    emits: ['refreshData', 'closed'],
    name: "addModuleGoodsBreed",
    data() {
        return {
            dialogVisible: false,
            loadingSave: false,
            loadingTitle: '',
            formData: {
                name: '',
                sort: 1,
                breed_spec_type: 1,
                flower_type: 1
            },
            rules: {
                name: [
                    { required: true, message: '请填写品种名称', trigger: 'blur' }
                ],
                goods_name: [
                    { required: true, message: '请填写商品名称', trigger: 'blur' }
                ],
                type: [
                    { required: true, message: '请选择分类', trigger: 'blur' }
                ],
                color: [
                    { required: true, message: '请选择颜色', trigger: 'blur' }
                ],
                label: [
                    { required: true, message: '请选择标签', trigger: 'blur' }
                ],
                cover: [
                    { required: true, message: '请上传封面', trigger: 'blur' }
                ],
            },
            typeList: [],
            colorList: [],
            labelList: [],
        }
    },
    mounted() {
        window.addEventListener("focusin", this.onFocusIn, true);
    },
    unmounted() {
        window.removeEventListener("focusin", this.onFocusIn);
    },
    methods: {
        onFocusIn(e) {
            e.stopImmediatePropagation()//阻止当前和后面的一系列事件
        },
        handleClose() {
            this.$emit('closed')
        },
        handleOpen(item, flag) {
            this.loadingTitle = flag
            this.dialogVisible = true
            if (item) {
                this.formData = deepClone(item)
            }
            this.getSelectData()
        },
        getSelectData() {
            fmallGoodslabel({ page: 1, limit: 999 }).then(res => {
                if (res.code === 2000) {
                    this.labelList = res.data.data
                }
            })
            fmallGoodstype({ page: 1, limit: 999 }).then(res => {
                if (res.code === 2000) {
                    this.typeList = res.data.data
                }
            })
            fmallGoodscolor({ page: 1, limit: 999 }).then(res => {
                if (res.code === 2000) {
                    this.colorList = res.data.data
                }
            })
        },
        submitData() {
            this.$refs['rulesForm'].validate(obj => {
                if (obj) {
                    this.loadingSave = true
                    let param = {
                        ...this.formData
                    }
                    if (param.breed_spec_type == 1) {
                        if (!param.breed_spec) {
                            this.$message.warning("请填写品种规格")
                            return
                        }
                        if (!param.spec_desc) {
                            this.$message.warning("请填写品种备注")
                            return
                        }
                        if (!param.spec_weight) {
                            this.$message.warning("请填写规格估重")
                            return
                        }
                    }
                    if (this.formData.id) {
                        fmallGoodsBreedEdit(param).then(res => {
                            this.loadingSave = false
                            if (res.code == 2000) {
                                this.$message.success(res.msg)
                                this.handleClose()
                                this.$emit('refreshData')
                            } else {
                                this.$message.warning(res.msg)
                            }
                        })
                    } else {
                        fmallGoodsBreedAdd(param).then(res => {
                            this.loadingSave = false
                            if (res.code == 2000) {
                                this.$message.success(res.msg)
                                this.handleClose()
                                this.$emit('refreshData')
                            } else {
                                this.$message.warning(res.msg)
                            }
                        })
                    }

                }
            })
        },

    }
}
</script>

<style>
.set-specs .el-form-item__content {
    background: #e6e6e6 !important;
}
</style>

