<template>
    <div ref="orderPrint" class="print-template" style="font-size: 14px;padding-top: 20px;page-break-after: always;">
        <div style="display: flex;align-items: center;">
            <img :src="formData.zyImg" style="width:40px;height: 40px;border-radius: 60%;object-fit:contain;" />
            <div style="padding-left: 5px;">
                <h6 style="font-size: 16px;">{{ formData.zyName }}</h6>
                <div style="font-size: 11px;padding-top: 5px;">{{ formData.date }}</div>
            </div>

        </div>
        <h1 style="font-size: 25px;padding-top: 10px;padding-bottom: 10px;">{{ formData.goods_no }}</h1>
        <div style="font-size: 20px;padding-bottom: 10px;">{{ formData.print_kuaidi }}</div>
        <div style="font-size: 11px;">
            <span>{{ formData.address_name }}</span>
            <span style="padding-left: 5px;">{{ formData.address_mobile }}</span>
            <span style="padding-left: 5px;">{{ formData.address_place }}</span>
        </div>
        <div style="height: 260px;padding-top: 20px;font-size: 10px;">
            <span>{{ formData.goods_info }}</span>
        </div>
        <div style="font-size: 12px;">
            <div>总数：{{ formData.total_count }}扎</div>
            <div style="padding-top: 3px;">客服：{{ formData.kefu }}</div>
        </div>
    </div>
</template>

<script>
import print from '@/utils/print'
import { fmallOrderPrintOrder } from '@/api/api'
import { deepClone } from "@/utils/util"
export default {
    name: "modulePrintOrder",
    emits: ['closed'],
    data() {
        return {
            formData: {
                id: "",
                zyImg: '',
                zyName: '',
                date: '',
                goods_no: '',
                goods_info: '',
                total_count: 0,
                kefu: ""
            },
        }
    },
    methods: {
        handleClose() {
            this.$emit('closed')
        },
        handleOpen(item) {
            if (item) {
                this.formData = deepClone(item)
            }
            this.getPrintData()
        },
        handlePrint() {
            print(this.$refs.orderPrint)
            // const PrintContent = this.$refs.orderPrint
            // const PrintWindow = window.open("", "_blank")
            // const htmlContent = `
            //     <html>
            //         <head>
            //             <title></title>
            //             <style>
            //             .print-template {
            //                 display: none;
            //                 /* 默认不显示 */
            //             }
            //             @media print {
            //                 .print-template {
            //                     display: block;
            //                     /* 打印时显示 */
            //                 }

            //                 /* 去除页眉和页脚 */
            //                 @page {
            //                     margin-top: 0;
            //                     margin-bottom: 0;
            //                 }
            //             }
            //             </style>
            //         </head>
            //         <body>
            //             ${PrintContent.innerHTML}
            //         </body>
            //     </html>
            // `
            // PrintWindow.document.write(htmlContent)
            // PrintWindow.document.close()
            // PrintWindow.onload = () => {
            //     PrintWindow.print()
            //     PrintWindow.close()
            // }
        },
        getPrintData() {
            fmallOrderPrintOrder({ id: this.formData.id }).then(res => {
                if (res.code === 2000) {
                    Object.assign(this.formData, res.data);
                    this.$nextTick(() => {
                        this.handlePrint()
                    })
                }
            })
        }
    }
}
</script>
<style scoped>
.print-template {
    display: none;
    /* 默认不显示 */
}

@media print {
    .print-template {
        display: block;
        /* 打印时显示 */
    }

    /* 去除页眉和页脚 */
    @page {
        margin-top: 0;
        margin-bottom: 0;
        margin-left: 20px;
        margin-right: 20px;
    }
}
</style>

