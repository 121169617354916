<template>
    <div>
        <ly-dialog v-model="dialogVisible" :title="loadingTitle" width="560px" @closed="handleClose">
            <el-form :inline="false" :model="formData" :rules="rules" ref="rulesForm" label-position="right"
                label-width="auto">
                <el-form-item label="物流公司：" prop="logistics_company_code">
                    <el-select v-model="formData.logistics_company_code" placeholder="请选择" @change="handleChange" filterable
                        clearable style="width: 100%;">
                        <el-option v-for="item in wuliuList" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="物流公司：" prop="logistics_company" v-show="false">
                    <el-input v-model.trim="formData.logistics_company"></el-input>
                </el-form-item>
                <el-form-item label="物流单号：" prop="logistics_id">
                    <el-input v-model.trim="formData.logistics_id"></el-input>
                </el-form-item>
            </el-form>
            <template #footer>
                <el-button @click="handleClose" :loading="loadingSave">取消</el-button>
                <el-button type="primary" @click="submitData" :loading="loadingSave">确定</el-button>
            </template>
        </ly-dialog>
    </div>
</template>

<script>
import { fmallOrderSendgoods } from "@/api/api";
import LyDialog from "@/components/dialog/dialog";
import LyUploadAvatar from "@/components/upload/avatar";
export default {
    name: "moduleDeliverGoodsManual",
    components: { LyDialog, LyUploadAvatar },
    emits: ['refreshData', 'closed'],
    data() {
        return {
            dialogVisible: false,
            loadingSave: false,
            loadingTitle: '',
            formData: {
                ids: [],
                logistics_company_code: '',
                logistics_company: '',
                logistics_id: '',
                manual: 1,//手动发货
            },
            rules: {
                // logistics_company_code: [
                //     { required: true, message: '请选择物流公司', trigger: 'blur' }
                // ],
                // logistics_id: [
                //     { required: true, message: '请输入物流单号', trigger: 'blur' }
                // ],
            },
            wuliuList: [
                { id: 'zhongtong', name: '中通快递' },
                { id: 'yuantong', name: '圆通快递' },
                { id: 'huitongkuaidi', name: '百世快递' },
                { id: 'yunda', name: '韵达快递' },
                { id: 'youzhengguonei', name: '邮政快递包裹' },
                { id: 'youzhengbk', name: '邮政标准快递' },
                { id: 'jd', name: '京东快递' },
                { id: 'shunfeng', name: '顺丰快递' },
                { id: 'jtexpress', name: '极兔速递' },
                { id: 'shentong', name: '申通快递' },
                { id: 'ems', name: 'EMS' },
            ]
        }
    },
    methods: {
        handleClose() {
            this.$emit('closed')
        },
        handleOpen(item, flag) {
            this.loadingTitle = flag
            this.dialogVisible = true
            this.formData.ids = []
            this.formData.ids.push(item.id)
        },
        handleChange(e) {
            this.formData.logistics_company = this.wuliuList.filter(item => item.id == e)[0].name
        },
        submitData() {
            this.$refs['rulesForm'].validate(obj => {
                if (obj) {
                    this.loadingSave = true
                    let param = {
                        ...this.formData
                    }
                    param.ids = JSON.stringify(param.ids)
                    fmallOrderSendgoods(param).then(res => {
                        this.loadingSave = false
                        if (res.code == 2000) {
                            this.$message.success("发货成功")
                            this.handleClose()
                            this.$emit('refreshData')
                        } else {
                            let vm = this
                            this.$confirm(res.msg, '警告', {
                                showCancelButton: false,
                                closeOnClickModal: false,
                                type: 'warning'
                            }).then(ret => {
                                vm.$emit('refreshData')
                            }).catch(() => {

                            })

                        }
                    })

                }
            })
        },
    }
}
</script>
<style scoped>
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 128px;
    height: 128px;
    line-height: 128px;
    text-align: center;
}

.avatar {
    width: 128px;
    height: 128px;
    display: block;
}
</style>

