<template>
    <div>
        <ly-dialog v-model="dialogVisible" width="560px" title="审核" @closed="handleClose">
            <el-form :model="formData" :rules="rules" ref="rulesForm" label-position="right" label-width="100px">
                <el-form-item label="银行名称：">
                    {{ formData.bank_name }}
                </el-form-item>
                <el-form-item label="银行卡号：">
                    {{ formData.bank_no }}
                </el-form-item>
                <el-form-item label="提交金额：">
                    <el-input-number v-model="formData.money" style="width: 100%;" :controls="false" />
                </el-form-item>
            </el-form>
            <template v-slot:footer>
                <el-button type="primary" @click="submitData" :loading="loadingSave">提交</el-button>
            </template>
        </ly-dialog>
    </div>
</template>

<script>
import LyDialog from "@/components/dialog/dialog";
import { fmallblcAgentRecordApply, systemUserUserInfo } from "@/api/api";
import { deepClone } from "@/utils/util";
export default {
    name: "auditModuleCashoutAgent",
    components: { LyDialog },
    data() {
        return {
            dialogVisible: false,
            loadingSave: false,
            loadingTitle: '',
            tempdata: "",
            formData: {
                bank_name: '',
                money: null,
                bank_no: '',
            },
            rules: {
                money: [
                    { required: true, message: '请输入金额', trigger: 'blur' }
                ],
            },
        }
    },
    methods: {
        handleClose() {
            this.$emit('closed')
        },
        handleOpen(item, flag) {
            this.dialogVisible = true
            this.loadingTitle = flag
            this.getUserinfo()
        },
        getUserinfo() {
            systemUserUserInfo().then(res => {
                if (res.code == 2000) {
                    this.formData.bank_name = res.data.data.bank_name
                    this.formData.bank_no = res.data.data.bank_no
                }
            })
        },
        submitData() {
            this.$refs['rulesForm'].validate(obj => {
                if (obj) {
                    if (this.formData.bank_name == "" || this.formData.bank_no == "") {
                        this.$message.warning("请填写银行名称和银行卡号")
                        return
                    }
                    this.loadingSave = true
                    fmallblcAgentRecordApply(this.formData).then(res => {
                        this.loadingSave = false
                        if (res.code == 2000) {
                            this.$message.success(res.msg)
                            this.handleClose()
                            this.$emit('refreshData')
                        } else {
                            this.$message.warning(res.msg)
                        }
                    })
                }
            })
        },

    }
}
</script>

