<template>
    <div>
        <ly-dialog v-model="dialogVisible" :title="loadingTitle" width="50%" :before-close="handleClose">
            <el-form :inline="false" :model="formData" :rules="rules" ref="rulesForm" label-position="right"
                label-width="auto">
                <el-form-item label="名称：" prop="title">
                    <el-input type="text" v-model.trim="formData.title"></el-input>
                </el-form-item>
                <el-form-item label="轮播图" prop="image">
                    <el-upload class="avatar-uploader" action="" :show-file-list="false" :http-request="imgUploadRequest"
                        :on-success="imgUploadSuccess" :before-upload="imgBeforeUpload">
                        <img v-if="formData.image" :src="formData.image" class="avatar" />
                        <el-icon v-else class="avatar-uploader-icon" size="medium">
                            <Plus />
                        </el-icon>
                    </el-upload>
                </el-form-item>
                <el-form-item label="排序：" prop="sort">
                    <el-input-number v-model="formData.sort" placeholder="数字越大越靠前" style="width: 100%;"></el-input-number>
                </el-form-item>
                <el-form-item label="位置：" prop="type">
                    <el-select v-model="formData.type" placeholder="请选择" style="width: 100%;" filterable clearable
                        :disabled="true">
                        <el-option v-for=" item  in  typeList " :key="item.id" :label="item.name" :value="item.id" />
                    </el-select>
                </el-form-item>
                <el-form-item label="选择文章：" prop="article"
                    v-if="formData.type == 2 || formData.type == 3 || formData.type == 4 || formData.type == 5">
                    <el-select v-model="formData.article" placeholder="请选择" style="width: 100%;" filterable clearable>
                        <el-option v-for=" item  in  articleList " :key="item.id" :label="item.title" :value="item.id" />
                    </el-select>
                </el-form-item>
                <el-form-item label="文章内容：" prop="link" v-if="formData.type == 6">
                    <TEditor v-model="formData.link" style="height: 300px;"></TEditor>
                </el-form-item>
                <!-- <el-form-item label="状态：" prop="status">
                    <el-switch
                            v-model="formData.status"
                            active-color="#13ce66"
                            inactive-color="#ff4949">
                    </el-switch>
                </el-form-item> -->
            </el-form>
            <template #footer>
                <el-button @click="handleClose" :loading="loadingSave">取消</el-button>
                <el-button type="primary" @click="submitData" :loading="loadingSave">确定</el-button>
            </template>
        </ly-dialog>
    </div>
</template>

<script>
import { platformsettingsLunboimgAdd, platformsettingsLunboimgEdit, platformsettingsUploadPlatformImg, fmallNotice } from "@/api/api";
import { url } from '@/api/url'
import LyDialog from "@/components/dialog/dialog";
import { deepClone } from "@/utils/util";
import TEditor from '@/components/TEditor'
export default {
    components: { LyDialog, TEditor },
    emits: ['refreshData'],
    name: "addModule",
    data() {
        return {
            dialogVisible: false,
            loadingSave: false,
            loadingTitle: '',
            peopleList: [],
            formData: {
                title: '',
                link: '',
                image: '',
                type: '',
                sort: 1,
                article: "",
                status: true
            },
            rules: {
                image: [
                    { required: true, message: '请上传图片', trigger: 'blur' }
                ],
                link: [
                    { required: true, message: '请输入链接', trigger: 'blur' }
                ],
                title: [
                    { required: true, message: '请输入标题', trigger: 'blur' }
                ]
            },
            typeList: [
                { id: 1, name: "首页轮播图" },
                { id: 2, name: "首页弹窗" },
                { id: 3, name: "首页广告左" },
                { id: 4, name: "首页广告右上" },
                { id: 5, name: "首页广告右下" },
                { id: 6, name: "整件团购" },
            ],
            articleList: [],
        }
    },
    methods: {
        handleClose() {
            this.dialogVisible = false
            this.loadingSave = false
            this.formData = {
                title: '',
                link: '',
                image: '',
                type: "",
                sort: 1,
                article: "",
                status: true
            }
        },
        getArticleList() {
            fmallNotice({ page: 1, limit: 999 }).then(res => {
                if (res.code === 2000) {
                    this.articleList = res.data.data
                }
            })
        },
        addModuleFn(item, flag, activeName) {
            this.loadingTitle = flag
            this.dialogVisible = true
            if (item) {
                this.formData = deepClone(item)
            }
            this.formData.type = activeName
            let articleList = [2, 3, 4, 5]
            if (articleList.indexOf(this.formData.type) != -1) {
                this.getArticleList()
            }
        },
        submitData() {
            this.$refs['rulesForm'].validate(obj => {
                if (obj) {
                    this.loadingSave = true
                    // let newimage = ""
                    // if (this.formData.image.indexOf("://")>=0){
                    //     newimage = this.formData.image.substring(this.formData.image.indexOf("/",8))
                    // }else{
                    //     newimage = this.formData.image
                    // }
                    // this.formData.image = newimage
                    let param = {
                        ...this.formData
                    }
                    if (this.formData.id) {
                        platformsettingsLunboimgEdit(param).then(res => {
                            this.loadingSave = false
                            if (res.code == 2000) {
                                this.$message.success(res.msg)
                                this.handleClose()
                                this.$emit('refreshData')
                            } else {
                                this.$message.warning(res.msg)
                            }
                        })
                    } else {
                        platformsettingsLunboimgAdd(param).then(res => {
                            this.loadingSave = false
                            if (res.code == 2000) {
                                this.$message.success(res.msg)
                                this.handleClose()
                                this.$emit('refreshData')
                            } else {
                                this.$message.warning(res.msg)
                            }
                        })
                    }

                }
            })
        },
        imgBeforeUpload(file) {
            const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
            if (!isJPG) {
                this.$message.error('图片只能是 JPG/PNG 格式!');
                return false
            }
            return isJPG;
        },
        async imgUploadRequest(param) {
            var vm = this
            let obj = await platformsettingsUploadPlatformImg(param)
            if (obj.code == 2000) {
                let res = ''
                if (obj.data.data[0].indexOf("://") >= 0) {
                    res = obj.data.data[0]

                } else {
                    res = url.split('/api')[0] + obj.data.data[0]
                }
                vm.formData.image = res
            } else {
                vm.$message.warning(res.msg)
            }
        },
        imgUploadSuccess(res) {
            // if (res) {
            //     this.formData.image = res.url
            // }
        }
    }
}
</script>
<style scoped>
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 128px;
    height: 128px;
    line-height: 128px;
    text-align: center;
}

.avatar {
    width: 128px;
    height: 128px;
    display: block;
}
</style>

