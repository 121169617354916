<template>
    <div :class="{ 'ly-is-full': isFull }">
        <div class="tableSelect" ref="tableSelect">
            <el-form :inline="false" :model="formInline" label-width="auto">
                <div style="width: 100%;">
                    <el-row :gutter="20">
                        <el-col :span="6">
                            <el-form-item label="昵称：">
                                <el-input v-model.trim="formInline.nickname" maxlength="60" clearable placeholder="昵称"
                                    @change="search"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="手机号：">
                                <el-input v-model.trim="formInline.mobile" maxlength="60" clearable placeholder="手机号"
                                    @change="search"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="城市：">
                                <el-input v-model.trim="formInline.cityname" maxlength="60" clearable placeholder="城市"
                                    @change="search"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="代理：">
                                <el-input v-model.trim="formInline.agentname" maxlength="60" clearable placeholder="代理"
                                    @change="search"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="客服：">
                                <el-input v-model.trim="formInline.kefuname" maxlength="60" clearable placeholder="客服"
                                    @change="search"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="状态：">
                                <el-select size="default" v-model="formInline.is_active" placeholder="请选择" clearable
                                    style="width: 100%" @change="search">
                                    <el-option v-for="item in statusList" :key="item.id" :label="item.name"
                                        :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="时间：">
                                <el-date-picker style="width:100%" v-model="timers" type="datetimerange"
                                    @change="timeChange" range-separator="至" start-placeholder="开始时间"
                                    end-placeholder="结束时间">
                                </el-date-picker>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="">
                                <el-button @click="search" type="primary" icon="Search"
                                    v-show="hasPermission(this.$route.name, 'Search')">查询</el-button>
                                <el-button @click="handleEdit('', 'reset')" icon="Refresh">重置</el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </div>
            </el-form>
        </div>

        <div class="table">
            <el-table :height="tableHeight" border :data="tableData" ref="tableref" v-loading="loadingPage">
                <!-- <el-table-column type="index" width="60" align="center" label="序号">
                    <template #default="scope">
                        <span v-text="getIndex(scope.$index)"></span>
                    </template>
                </el-table-column> -->
                <el-table-column min-width="70" prop="avatar" label="用户头像">
                    <template #default="scope">
                        <el-image :src="scope.row.avatar" style="width: 30px;height: 30px"
                            :preview-src-list="[scope.row.avatar]" :preview-teleported="true"></el-image>
                    </template>
                </el-table-column>
                <el-table-column min-width="110" prop="nickname" label="昵称"></el-table-column>
                <el-table-column min-width="100" prop="mobile" label="手机号"></el-table-column>
                <el-table-column min-width="100" prop="userinfo.cityname" label="所属城市"></el-table-column>
                <el-table-column min-width="100" prop="userinfo.agentname" label="所属代理"></el-table-column>
                <el-table-column min-width="100" prop="userinfo.kefuname" label="所属客服"></el-table-column>
                <el-table-column min-width="100" label="状态">
                    <template #default="scope">
                        <el-switch v-model="scope.row.is_active" active-color="#13ce66" inactive-color="#ff4949"
                            @change="changeStatus(scope.row)">
                        </el-switch>
                    </template>
                </el-table-column>
                <el-table-column min-width="150" prop="create_datetime" label="创建时间"></el-table-column>
                <el-table-column label="操作" fixed="right" width="180">
                    <template #header>
                        <div style="display: flex;justify-content: space-between;align-items: center;">
                            <div>操作</div>
                            <div @click="setFull">
                                <el-tooltip content="全屏" placement="bottom">
                                    <el-icon><full-screen /></el-icon>
                                </el-tooltip>
                            </div>
                        </div>
                    </template>
                    <template #default="scope">
                        <!-- <span class="table-operate-btn" @click="handleEdit(scope.row, 'edit')"
                            v-show="hasPermission(this.$route.name, 'Update')">编辑</span>
                        <span class="table-operate-btn" @click="handleEdit(scope.row, 'delete')"
                            v-show="hasPermission(this.$route.name, 'Delete')">删除</span> -->
                        <span class="table-operate-btn" @click="handleEdit(scope.row, 'watchorder')">查看订单</span>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <Pagination v-bind:child-msg="pageparm" @callFather="callFather"></Pagination>
        <addUser ref="addUserFlag" @refreshData="getData" v-if="isDialogVisible" @closed="isDialogVisible = false">
        </addUser>
    </div>
</template>
<script>
import addUser from "./components/addUser";
import Pagination from "@/components/Pagination";
import { dateFormats, getTableHeight } from "@/utils/util";
import { UsersUsers, UsersUsersDelete, UsersUsersdisableEdit } from '@/api/api'
export default {
    components: {
        Pagination,
        addUser
    },
    name: 'userManage',
    data() {
        return {
            isFull: false,
            tableHeight: 500,
            loadingPage: false,
            formInline: {
                page: 1,
                limit: 10,
            },
            defaultImg: require('../../assets/img/avatar.jpg'),
            pageparm: {
                page: 1,
                limit: 10,
                total: 0
            },
            statusList: [
                { id: 1, name: '正常' },
                { id: 0, name: '禁用' }
            ],
            identityList: [
                { id: 0, name: '普通用户' },
                { id: 1, name: '会员' },
            ],
            timers: [],
            tableData: [],
            isDialogVisible: false,
        }
    },

    methods: {
        // 表格序列号
        getIndex($index) {
            // (当前页 - 1) * 当前显示数据条数 + 当前行数据的索引 + 1
            return (this.pageparm.page - 1) * this.pageparm.limit + $index + 1
        },
        setFull() {
            this.isFull = !this.isFull
            window.dispatchEvent(new Event('resize'))
        },
        changeStatus(row) {
            let vm = this
            let flat = row.is_active
            row.is_active = !row.is_active
            vm.$confirm('确定修改状态吗？', '提醒', {
                closeOnClickModal: false,
                type: 'warning'
            }).then(res => {
                UsersUsersdisableEdit({ id: row.id }).then(res => {
                    if (res.code == 2000) {
                        flat ? row.is_active = true : row.is_active = false
                        vm.$message.success(res.msg)
                        vm.getData()
                    } else {
                        vm.$message.warning(res.msg)
                    }
                })
            }).catch(() => {

            })
        },
        handelAddClick() {
            this.isDialogVisible = true
            this.$nextTick(() => {
                this.$refs.addUserFlag.handleOpen(null, "新增")
            })
        },
        handleEdit(row, flag) {
            if (flag == 'edit') {
                this.isDialogVisible = true
                this.$nextTick(() => {
                    this.$refs.addUserFlag.handleOpen(row, "编辑")
                })
            }
            else if (flag == 'watchorder') {
                this.$router.push({ name: 'mallOrderListManage', query: { creator_id: row.id } });
            }
            else if (flag == 'disable') {
                let vm = this
                UsersUsersdisableEdit({ id: row.id }).then(res => {
                    if (res.code == 2000) {
                        vm.$message.success(res.msg)
                        vm.search()
                    } else {
                        vm.$message.warning(res.msg)
                    }
                })
            }
            else if (flag == 'delete') {
                let vm = this
                vm.$confirm('您确定要删除选中的数据吗？', {
                    closeOnClickModal: false
                }).then(res => {
                    UsersUsersDelete({ id: row.id }).then(res => {
                        if (res.code == 2000) {
                            vm.$message.success(res.msg)
                            vm.search()
                        } else {
                            vm.$message.warning(res.msg)
                        }
                    })
                }).catch(() => {

                })
            }
            else if (flag == "reset") {
                this.formInline = {
                    page: 1,
                    limit: 10
                }
                this.pageparm = {
                    page: 1,
                    limit: 10,
                    total: 0
                }
                this.getData()
            }
        },
        /**
         * 从URL里下载文件
        */
        // 下载文件
        downloadFileURL(url) {
            var iframe = document.createElement("iframe")
            iframe.style.display = "none";
            iframe.src = url;
            document.body.appendChild(iframe);
        },
        exportDataBackend() {
            var params = {
                page: 1,
                limit: 9999,
            }
            // UsersUsersExportexecl(params).then(res => {
            //     if (res.code == 2000) {
            //         this.downloadFileURL(res.data.data)
            //         //this.$message.warning(res.data.data)
            //     }
            // })
        },
        callFather(parm) {
            this.formInline.page = parm.page
            this.formInline.limit = parm.limit
            this.getData()
        },
        search() {
            this.formInline.page = 1
            this.formInline.limit = 10
            this.getData()
        },
        //获取列表
        async getData() {
            this.loadingPage = true
            UsersUsers(this.formInline).then(res => {
                this.loadingPage = false
                if (res.code == 2000) {
                    this.tableData = res.data.data
                    this.pageparm.page = res.data.page;
                    this.pageparm.limit = res.data.limit;
                    this.pageparm.total = res.data.total;
                }
            })
        },

        timeChange(val) {
            if (val) {
                this.formInline.beginAt = dateFormats(val[0], 'yyyy-MM-dd hh:mm:ss');
                this.formInline.endAt = dateFormats(val[1], 'yyyy-MM-dd hh:mm:ss');
            } else {
                this.formInline.beginAt = null
                this.formInline.endAt = null
            }
            this.search()
        },
        // 计算搜索栏的高度
        listenResize() {
            this.$nextTick(() => {
                this.getTheTableHeight()
            })
        },
        getTheTableHeight() {
            let tabSelectHeight = this.$refs.tableSelect ? this.$refs.tableSelect.offsetHeight : 0
            tabSelectHeight = this.isFull ? tabSelectHeight - 110 : tabSelectHeight
            this.tableHeight = getTableHeight(tabSelectHeight)
        }

    },
    created() {
        this.getData()
    },
    mounted() {
        // 监听页面宽度变化搜索框的高度
        window.addEventListener('resize', this.listenResize);
        this.$nextTick(() => {
            this.getTheTableHeight()
        })
    },

    unmounted() {
        // 页面销毁，去掉监听事件
        window.removeEventListener("resize", this.listenResize);
    },
}
</script>
